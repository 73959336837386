@font-face {
  font-family: "NEXON Lv2 Gothic";
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/NEXONLv2Gothic/NEXONLv2GothicLight.eot");
  src: url("/assets/fonts/NEXONLv2Gothic/NEXONLv2GothicLight.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "NEXON Lv2 Gothic";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/NEXONLv2Gothic/NEXONLv2Gothic.eot");
  src: url("/assets/fonts/NEXONLv2Gothic/NEXONLv2Gothic.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "NEXON Lv2 Gothic";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/NEXONLv2Gothic/NEXONLv2GothicMedium.eot");
  src: url("/assets/fonts/NEXONLv2Gothic/NEXONLv2GothicMedium.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "NEXON Lv2 Gothic";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/NEXONLv2Gothic/NEXONLv2GothicBold.eot");
  src: url("/assets/fonts/NEXONLv2Gothic/NEXONLv2GothicBold.woff") format("woff");
  font-display: swap;
}
