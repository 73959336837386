@use "sass:map";
@use "sass:math";
@use "colors" as c;
@use "color-functions" as cf;
@use "functions" as f;
@use "units" as *;

@import "./NEXONLv2Gothic.scss";

:root {
  font-family: "NEXON Lv2 Gothic", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
    "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  --ion-font-family: "NEXON Lv2 Gothic", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
    "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.md body,
.ios body {
  $text-color: #252525;
  $background-color: #ffffff;

  --ion-text-color: #{$text-color};
  --ion-text-color-rgb: #{f.to-rgb($text-color)};
  --ion-background-color: #{$background-color};
  --ion-background-color-rgb: #{f.to-rgb($background-color)};
  --ion-backdrop-color: #000000;
  --ion-backdrop-opacity: 0.2;
  --ion-border-color: #757575;
  --ion-tab-bar-background: #fdfdfd;
  --ion-placeholder-color: #757575;

  --ion-item-border-color: var(--ion-color-light);
}

@media (prefers-color-scheme: dark) {
  .md body,
  .ios body {
    $text-color: #ffffff;
    $background-color: #121212;

    --ion-text-color: #{$text-color};
    --ion-text-color-rgb: #{f.to-rgb($text-color)};
    --ion-background-color: #{$background-color};
    --ion-background-color-rgb: #{f.to-rgb($background-color)};
    --ion-backdrop-color: #000000;
    --ion-backdrop-opacity: 0.6;
    --ion-border-color: #757575;
    --ion-tab-bar-background: #000000;
    --ion-placeholder-color: #757575;

    --ion-item-border-color: var(--ion-color-light);
  }
}

:root {
  @each $color-name, $color in c.$colors {
    --ch-color-#{$color-name}: #{map.get($color, base)};
    --ch-color-#{$color-name}-rgb: #{map.get($color, base-rgb)};
    --ch-color-#{$color-name}-contrast: #{map.get($color, contrast)};
    --ch-color-#{$color-name}-contrast-rgb: #{map.get($color, contrast-rgb)};
    --ch-color-#{$color-name}-shade: #{map.get($color, shade)};
    --ch-color-#{$color-name}-tint: #{map.get($color, tint)};
  }
}

@each $color-name, $color in c.$colors {
  .ch-color-#{$color-name},
  [data-ch-color="#{$color-name}"] {
    --ch-color-base: #{map.get($color, base)};
    --ch-color-base-rgb: #{map.get($color, base-rgb)};
    --ch-color-contrast: #{map.get($color, contrast)};
    --ch-color-contrast-rgb: #{map.get($color, contrast-rgb)};
    --ch-color-shade: #{map.get($color, shade)};
    --ch-color-tint: #{map.get($color, tint)};
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @each $color-name, $color in c.$colors-dark {
      --ch-color-#{$color-name}: #{map.get($color, base)};
      --ch-color-#{$color-name}-rgb: #{map.get($color, base-rgb)};
      --ch-color-#{$color-name}-contrast: #{map.get($color, contrast)};
      --ch-color-#{$color-name}-contrast-rgb: #{map.get($color, contrast-rgb)};
      --ch-color-#{$color-name}-shade: #{map.get($color, shade)};
      --ch-color-#{$color-name}-tint: #{map.get($color, tint)};
    }
  }

  @each $color-name, $color in c.$colors-dark {
    .ch-color-#{$color-name},
    [data-ch-color="#{$color-name}"] {
      --ch-color-base: #{map.get($color, base)};
      --ch-color-base-rgb: #{map.get($color, base-rgb)};
      --ch-color-contrast: #{map.get($color, contrast)};
      --ch-color-contrast-rgb: #{map.get($color, contrast-rgb)};
      --ch-color-shade: #{map.get($color, shade)};
      --ch-color-tint: #{map.get($color, tint)};
    }
  }
}

img:not([draggable]) {
  -webkit-user-drag: none;
}

ion-back-button {
  --min-width: 2rem;
  --min-height: 2rem;
  --icon-font-size: 1.5rem;
}

ion-footer.ch-new {
  &::before {
    display: none;
  }
}

ion-footer.empty-footer {
  height: var(--ion-safe-area-bottom, 0);
  background: transparent;

  &::before {
    display: none;
  }
}

.sc-ion-buttons-ios-s ion-button {
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
}

ion-item:not(.ch-new) {
  --padding-start: 1.25rem;
  --padding-end: 1.25rem;
  --inner-padding-start: 0;
  --inner-padding-end: 0;

  &:last-child {
    --inner-border-width: 0;
  }
}

ion-item.ch-new {
  --padding-start: #{dp(15)};
  --padding-end: #{dp(15)};
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  font-size: sp(14);
  font-weight: 400;

  &:last-child {
    --inner-border-width: 0;
  }
}

ion-list-header:not(.ch-new) {
  --padding-start: 1.25rem;
  --padding-end: 1.25rem;
  padding-inline-start: calc(var(--ion-safe-area-left, 0) + var(--padding-start, 0));
  padding-inline-end: calc(var(--ion-safe-area-right, 0) + var(--padding-end, 0));
  font-size: 1rem;
  font-weight: 600;

  @supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
    padding-left: unset;
    padding-inline-start: calc(var(--ion-safe-area-left, 0) + var(--padding-start, 0));
    padding-inline-end: calc(var(--ion-safe-area-right, 0) + var(--padding-end, 0));
  }
}

ion-list-header.ch-new {
  --padding-start: #{dp(15)};
  --padding-end: #{dp(15)};
  min-height: dp(45);
  font-size: sp(14);
  font-weight: 500;
  color: cf.get-color-var(gray-700);
}

ion-title {
  font-size: 1rem;
  font-weight: 400;
}

ion-toolbar {
  --padding-top: 1rem;
  --padding-bottom: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --min-height: 4.5rem;
  --ion-toolbar-color: var(--ion-text-color);

  &.ios {
    --background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
  }

  ion-title {
    --padding-top: 3px;
    --padding-bottom: 3px;
    --padding-start: 4px;
    --padding-end: 4px;
  }
}

ion-toolbar.ch-new {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: #{dp(10)};
  --padding-end: #{dp(10)};
  --min-height: #{dp(54)};

  ion-title {
    padding: 0 dp(5);
    font-size: sp(15);
    font-weight: 500;
  }

  ion-buttons {
    margin: 0;

    .button-has-icon-only.button-clear {
      --padding-top: 0;
      --padding-end: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      --border-radius: 50%;
      width: dp(35);
      height: dp(35);
      font-size: dp(30);
    }
  }

  ion-back-button {
    --padding-start: 0;
    --padding-end: 0;
    --min-width: #{dp(30)};
    --min-height: #{dp(30)};
    --icon-font-size: #{dp(30)};
    min-width: dp(30);
    color: cf.get-color-var(gray-600);
  }
}

ion-tab-bar {
  --border: none;
}

ion-button,
ion-segment-button {
  text-transform: none;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

.header-md.transparent,
.header-ios.transparent {
  --ion-toolbar-background: transparent;
}

.header-md.transparent::after {
  display: none;
}

.header-ios.transparent ion-toolbar:last-of-type {
  --border-width: 0;
}

ion-header.ch-new {
  &::after {
    display: none;
  }

  ion-toolbar:last-of-type {
    --border-width: 0;
  }
}

ion-header.empty-header {
  height: var(--ion-safe-area-top, 0);
  background: transparent;

  &::after {
    display: none;
  }
}

ion-modal {
  &.app-modal-auto-width,
  &.app-modal-auto-size {
    --width: auto;
  }

  &.app-modal-auto-height,
  &.app-modal-auto-size {
    --height: auto;

    &.bottom {
      align-items: flex-end;
    }
  }

  &.app-modal-auto-width,
  &.app-modal-auto-height,
  &.app-modal-auto-size {
    --max-width: 80vw;
    --max-height: 80vh;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .app-modal-inner {
        max-width: 80vw;
        max-height: 80vh;
        overflow: auto;
      }
    }
  }

  &.app-modal-full-width,
  &.app-modal-full-size {
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
    --max-width: 100vw;
    --width: 100vw;
  }

  &.app-modal-full-height,
  &.app-modal-full-size {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --max-height: 100vh;
    --height: 100vh;
  }
}

.app-wspw {
  white-space: pre-wrap;
}

.app-wspl {
  white-space: pre-line;
}

.app-wbka {
  word-break: keep-all;
}

.plt-capacitor {
  app-business-info {
    display: none;
  }
}

.imp-dialog {
  background-color: rgba(var(--ion-color-light-contrast-rgb, 0, 0, 0), var(--ion-backdrop-opacity, 0.32));
}

ion-modal.app-alert {
  --border-radius: #{dp(15)};
  --min-width: #{dp(240)};
  --width: calc(100% - #{dp(30)});
  --max-width: #{dp(480)};
  --min-height: auto;
  --height: auto;
  --max-height: 90%;
  backdrop-filter: blur(#{dp(5)});

  &.app-alert-message-text-align-start {
    --alert-message-text-align: start;
  }

  &.app-alert-message-wbka {
    --alert-message-word-break: keep-all;
  }
}

[color="ch"] {
  --ion-color-base: var(--ch-color-base);
  --ion-color-base-rgb: var(--ch-color-base-rgb);
  --ion-color-contrast: var(--ch-color-contrast);
  --ion-color-contrast-rgb: var(--ch-color-contrast-rgb);
  --ion-color-shade: var(--ch-color-shade);
  --ion-color-tint: var(--ch-color-tint);
}

.ch-tmb {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  min-height: 100%;

  > .ch-tmb-top,
  > .ch-tmb-bottom {
    flex: 0 0 auto;
    min-height: 0;
  }

  > .ch-tmb-middle {
    flex: 1 0 auto;
    min-height: 0;
  }
}

.ch-button {
  margin: 0;
  text-transform: none;

  &.button-solid {
    --box-shadow: none;
  }

  &.button-outline {
    --border-width: #{dp(1)};
  }

  &.button-has-icon-only {
    --padding-start: 0;
    --padding-end: 0;

    ion-icon[slot="icon-only"] {
      font-size: dp(30);
    }
  }
}
