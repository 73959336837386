// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@use "functions" as f;

/** Ionic CSS Variables **/
:root {
  $color-primary: #e9334a;
  $color-secondary: #3dc2ff;
  $color-tertiary: #5260ff;
  $color-success: #2be6ae;
  $color-warning: #ffb703;
  $color-danger: #e9334a;
  $color-dark: #252525;
  $color-medium: #989898;
  $color-light: #efefef;
  $color-primary-contrast: f.a11y-contrast($color-primary);
  $color-secondary-contrast: f.a11y-contrast($color-secondary);
  $color-tertiary-contrast: f.a11y-contrast($color-tertiary);
  $color-success-contrast: f.a11y-contrast($color-success);
  $color-warning-contrast: f.a11y-contrast($color-warning);
  $color-danger-contrast: f.a11y-contrast($color-danger);
  $color-dark-contrast: f.a11y-contrast($color-dark);
  $color-medium-contrast: f.a11y-contrast($color-medium);
  $color-light-contrast: f.a11y-contrast($color-light);

  /** primary **/
  --ion-color-primary: #{$color-primary};
  --ion-color-primary-rgb: #{f.to-rgb($color-primary)};
  --ion-color-primary-contrast: #{$color-primary-contrast};
  --ion-color-primary-contrast-rgb: #{f.to-rgb($color-primary-contrast)};
  --ion-color-primary-shade: #{f.shade($color-primary, 12)};
  --ion-color-primary-tint: #{f.tint($color-primary, 10)};

  /** secondary **/
  --ion-color-secondary: #{$color-secondary};
  --ion-color-secondary-rgb: #{f.to-rgb($color-secondary)};
  --ion-color-secondary-contrast: #{$color-secondary-contrast};
  --ion-color-secondary-contrast-rgb: #{f.to-rgb($color-secondary-contrast)};
  --ion-color-secondary-shade: #{f.shade($color-secondary, 12)};
  --ion-color-secondary-tint: #{f.tint($color-secondary, 10)};

  /** tertiary **/
  --ion-color-tertiary: #{$color-tertiary};
  --ion-color-tertiary-rgb: #{f.to-rgb($color-tertiary)};
  --ion-color-tertiary-contrast: #{$color-tertiary-contrast};
  --ion-color-tertiary-contrast-rgb: #{f.to-rgb($color-tertiary-contrast)};
  --ion-color-tertiary-shade: #{f.shade($color-tertiary, 12)};
  --ion-color-tertiary-tint: #{f.tint($color-tertiary, 10)};

  /** success **/
  --ion-color-success: #{$color-success};
  --ion-color-success-rgb: #{f.to-rgb($color-success)};
  --ion-color-success-contrast: #{$color-success-contrast};
  --ion-color-success-contrast-rgb: #{f.to-rgb($color-success-contrast)};
  --ion-color-success-shade: #{f.shade($color-success, 12)};
  --ion-color-success-tint: #{f.tint($color-success, 10)};

  /** warning **/
  --ion-color-warning: #{$color-warning};
  --ion-color-warning-rgb: #{f.to-rgb($color-warning)};
  --ion-color-warning-contrast: #{$color-warning-contrast};
  --ion-color-warning-contrast-rgb: #{f.to-rgb($color-warning-contrast)};
  --ion-color-warning-shade: #{f.shade($color-warning, 12)};
  --ion-color-warning-tint: #{f.tint($color-warning, 10)};

  /** danger **/
  --ion-color-danger: #{$color-danger};
  --ion-color-danger-rgb: #{f.to-rgb($color-danger)};
  --ion-color-danger-contrast: #{$color-danger-contrast};
  --ion-color-danger-contrast-rgb: #{f.to-rgb($color-danger-contrast)};
  --ion-color-danger-shade: #{f.shade($color-danger, 12)};
  --ion-color-danger-tint: #{f.tint($color-danger, 10)};

  /** dark **/
  --ion-color-dark: #{$color-dark};
  --ion-color-dark-rgb: #{f.to-rgb($color-dark)};
  --ion-color-dark-contrast: #{$color-dark-contrast};
  --ion-color-dark-contrast-rgb: #{f.to-rgb($color-dark-contrast)};
  --ion-color-dark-shade: #{f.shade($color-dark, 12)};
  --ion-color-dark-tint: #{f.tint($color-dark, 10)};

  /** medium **/
  --ion-color-medium: #{$color-medium};
  --ion-color-medium-rgb: #{f.to-rgb($color-medium)};
  --ion-color-medium-contrast: #{$color-medium-contrast};
  --ion-color-medium-contrast-rgb: #{f.to-rgb($color-medium-contrast)};
  --ion-color-medium-shade: #{f.shade($color-medium, 12)};
  --ion-color-medium-tint: #{f.tint($color-medium, 10)};

  /** light **/
  --ion-color-light: #{$color-light};
  --ion-color-light-rgb: #{f.to-rgb($color-light)};
  --ion-color-light-contrast: #{$color-light-contrast};
  --ion-color-light-contrast-rgb: #{f.to-rgb($color-light-contrast)};
  --ion-color-light-shade: #{f.shade($color-light, 12)};
  --ion-color-light-tint: #{f.tint($color-light, 10)};
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    $color-primary: #e9334a;
    $color-secondary: #50c8ff;
    $color-tertiary: #6a64ff;
    $color-success: #2be6ae;
    $color-warning: #ffb703;
    $color-danger: #e9334a;
    $color-dark: #efefef;
    $color-medium: #989aa2;
    $color-light: #252525;
    $color-primary-contrast: f.a11y-contrast($color-primary);
    $color-secondary-contrast: f.a11y-contrast($color-secondary);
    $color-tertiary-contrast: f.a11y-contrast($color-tertiary);
    $color-success-contrast: f.a11y-contrast($color-success);
    $color-warning-contrast: f.a11y-contrast($color-warning);
    $color-danger-contrast: f.a11y-contrast($color-danger);
    $color-dark-contrast: f.a11y-contrast($color-dark);
    $color-medium-contrast: f.a11y-contrast($color-medium);
    $color-light-contrast: f.a11y-contrast($color-light);

    /** primary **/
    --ion-color-primary: #{$color-primary};
    --ion-color-primary-rgb: #{f.to-rgb($color-primary)};
    --ion-color-primary-contrast: #{$color-primary-contrast};
    --ion-color-primary-contrast-rgb: #{f.to-rgb($color-primary-contrast)};
    --ion-color-primary-shade: #{f.shade($color-primary, 12)};
    --ion-color-primary-tint: #{f.tint($color-primary, 10)};

    /** secondary **/
    --ion-color-secondary: #{$color-secondary};
    --ion-color-secondary-rgb: #{f.to-rgb($color-secondary)};
    --ion-color-secondary-contrast: #{$color-secondary-contrast};
    --ion-color-secondary-contrast-rgb: #{f.to-rgb($color-secondary-contrast)};
    --ion-color-secondary-shade: #{f.shade($color-secondary, 12)};
    --ion-color-secondary-tint: #{f.tint($color-secondary, 10)};

    /** tertiary **/
    --ion-color-tertiary: #{$color-tertiary};
    --ion-color-tertiary-rgb: #{f.to-rgb($color-tertiary)};
    --ion-color-tertiary-contrast: #{$color-tertiary-contrast};
    --ion-color-tertiary-contrast-rgb: #{f.to-rgb($color-tertiary-contrast)};
    --ion-color-tertiary-shade: #{f.shade($color-tertiary, 12)};
    --ion-color-tertiary-tint: #{f.tint($color-tertiary, 10)};

    /** success **/
    --ion-color-success: #{$color-success};
    --ion-color-success-rgb: #{f.to-rgb($color-success)};
    --ion-color-success-contrast: #{$color-success-contrast};
    --ion-color-success-contrast-rgb: #{f.to-rgb($color-success-contrast)};
    --ion-color-success-shade: #{f.shade($color-success, 12)};
    --ion-color-success-tint: #{f.tint($color-success, 10)};

    /** warning **/
    --ion-color-warning: #{$color-warning};
    --ion-color-warning-rgb: #{f.to-rgb($color-warning)};
    --ion-color-warning-contrast: #{$color-warning-contrast};
    --ion-color-warning-contrast-rgb: #{f.to-rgb($color-warning-contrast)};
    --ion-color-warning-shade: #{f.shade($color-warning, 12)};
    --ion-color-warning-tint: #{f.tint($color-warning, 10)};

    /** danger **/
    --ion-color-danger: #{$color-danger};
    --ion-color-danger-rgb: #{f.to-rgb($color-danger)};
    --ion-color-danger-contrast: #{$color-danger-contrast};
    --ion-color-danger-contrast-rgb: #{f.to-rgb($color-danger-contrast)};
    --ion-color-danger-shade: #{f.shade($color-danger, 12)};
    --ion-color-danger-tint: #{f.tint($color-danger, 10)};

    /** dark **/
    --ion-color-dark: #{$color-dark};
    --ion-color-dark-rgb: #{f.to-rgb($color-dark)};
    --ion-color-dark-contrast: #{$color-dark-contrast};
    --ion-color-dark-contrast-rgb: #{f.to-rgb($color-dark-contrast)};
    --ion-color-dark-shade: #{f.shade($color-dark, 12)};
    --ion-color-dark-tint: #{f.tint($color-dark, 10)};

    /** medium **/
    --ion-color-medium: #{$color-medium};
    --ion-color-medium-rgb: #{f.to-rgb($color-medium)};
    --ion-color-medium-contrast: #{$color-medium-contrast};
    --ion-color-medium-contrast-rgb: #{f.to-rgb($color-medium-contrast)};
    --ion-color-medium-shade: #{f.shade($color-medium, 12)};
    --ion-color-medium-tint: #{f.tint($color-medium, 10)};

    /** light **/
    --ion-color-light: #{$color-light};
    --ion-color-light-rgb: #{f.to-rgb($color-light)};
    --ion-color-light-contrast: #{$color-light-contrast};
    --ion-color-light-contrast-rgb: #{f.to-rgb($color-light-contrast)};
    --ion-color-light-shade: #{f.shade($color-light, 12)};
    --ion-color-light-tint: #{f.tint($color-light, 10)};
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    $background-color-value: #000000;
    $text-color-value: #ffffff;

    --ion-background-color: #{$background-color-value};
    --ion-background-color-rgb: #{f.to-rgb($background-color-value)};

    --ion-text-color: #{$text-color-value};
    --ion-text-color-rgb: #{f.to-rgb($text-color-value)};

    --ion-color-step-50: #{mix($text-color-value, $background-color-value, 5%)};
    --ion-color-step-100: #{mix($text-color-value, $background-color-value, 10%)};
    --ion-color-step-150: #{mix($text-color-value, $background-color-value, 15%)};
    --ion-color-step-200: #{mix($text-color-value, $background-color-value, 20%)};
    --ion-color-step-250: #{mix($text-color-value, $background-color-value, 25%)};
    --ion-color-step-300: #{mix($text-color-value, $background-color-value, 30%)};
    --ion-color-step-350: #{mix($text-color-value, $background-color-value, 35%)};
    --ion-color-step-400: #{mix($text-color-value, $background-color-value, 40%)};
    --ion-color-step-450: #{mix($text-color-value, $background-color-value, 45%)};
    --ion-color-step-500: #{mix($text-color-value, $background-color-value, 50%)};
    --ion-color-step-550: #{mix($text-color-value, $background-color-value, 55%)};
    --ion-color-step-600: #{mix($text-color-value, $background-color-value, 60%)};
    --ion-color-step-650: #{mix($text-color-value, $background-color-value, 65%)};
    --ion-color-step-700: #{mix($text-color-value, $background-color-value, 70%)};
    --ion-color-step-750: #{mix($text-color-value, $background-color-value, 75%)};
    --ion-color-step-800: #{mix($text-color-value, $background-color-value, 80%)};
    --ion-color-step-850: #{mix($text-color-value, $background-color-value, 85%)};
    --ion-color-step-900: #{mix($text-color-value, $background-color-value, 90%)};
    --ion-color-step-950: #{mix($text-color-value, $background-color-value, 95%)};

    --ion-item-background: #{$background-color-value};

    --ion-card-background: #{mix($text-color-value, $background-color-value, 11%)};
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    $background-color-value: #121212;
    $text-color-value: #ffffff;

    --ion-background-color: #{$background-color-value};
    --ion-background-color-rgb: #{f.to-rgb($background-color-value)};

    --ion-text-color: #{$text-color-value};
    --ion-text-color-rgb: #{f.to-rgb($text-color-value)};

    --ion-border-color: #{mix($text-color-value, $background-color-value, 6.75%)};

    --ion-color-step-50: #{mix($text-color-value, $background-color-value, 5%)};
    --ion-color-step-100: #{mix($text-color-value, $background-color-value, 10%)};
    --ion-color-step-150: #{mix($text-color-value, $background-color-value, 15%)};
    --ion-color-step-200: #{mix($text-color-value, $background-color-value, 20%)};
    --ion-color-step-250: #{mix($text-color-value, $background-color-value, 25%)};
    --ion-color-step-300: #{mix($text-color-value, $background-color-value, 30%)};
    --ion-color-step-350: #{mix($text-color-value, $background-color-value, 35%)};
    --ion-color-step-400: #{mix($text-color-value, $background-color-value, 40%)};
    --ion-color-step-450: #{mix($text-color-value, $background-color-value, 45%)};
    --ion-color-step-500: #{mix($text-color-value, $background-color-value, 50%)};
    --ion-color-step-550: #{mix($text-color-value, $background-color-value, 55%)};
    --ion-color-step-600: #{mix($text-color-value, $background-color-value, 60%)};
    --ion-color-step-650: #{mix($text-color-value, $background-color-value, 65%)};
    --ion-color-step-700: #{mix($text-color-value, $background-color-value, 70%)};
    --ion-color-step-750: #{mix($text-color-value, $background-color-value, 75%)};
    --ion-color-step-800: #{mix($text-color-value, $background-color-value, 80%)};
    --ion-color-step-850: #{mix($text-color-value, $background-color-value, 85%)};
    --ion-color-step-900: #{mix($text-color-value, $background-color-value, 90%)};
    --ion-color-step-950: #{mix($text-color-value, $background-color-value, 95%)};

    --ion-item-background: #{mix($text-color-value, $background-color-value, 5%)};

    --ion-toolbar-background: #{mix($text-color-value, $background-color-value, 5.5%)};

    --ion-tab-bar-background: #{mix($text-color-value, $background-color-value, 5.5%)};

    --ion-card-background: #{mix($text-color-value, $background-color-value, 5%)};
  }
}
